.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 40px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: #CADF9E;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore';
            color: #CADF9E;
            font-size: 18px;
            position: absolute;
            margin-top: 40px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }

    h2 {
        color: #C4CAB8;
        margin-top: 20px;
        font-weight: 400;
        font-size: 15px;
        font-family: sans-serif;
        letter-spacing: 2px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #CADF9E;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 3px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 20px;
        border: 1px solid #CADF9E;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #CADF9E;
            color: #313628;
        }
    }

    #resume-button {
        margin-left: 20px;
    }

    .mobile-break {
        display: none;
    }
    
}

@media screen and (max-width: 1100px) {
    .home-page {
        .mobile-break {
            display:block;
        }

        .text-zone {
            position: absolute;
            left: 10%;
            top: 45%;
            transform: translateY(-50%);
            width: 100%;
            max-height: 90%;
        }

        h1{
            font-weight: 300;
            font-size: 30px;
        }

        #resume-button,
        .flat-button {
            clear:both;
            margin-left: 0px;
            display: block;
        }

        .profilepic {
            display: none;
        }
    }



}