.write {
  padding-top: 100px;
}

.writeImg {
  margin-left: 150px;
  width: 70vw;
  max-height: 650px;
  border-radius: 10px;
  object-fit: contain;
}

.writeForm {
  position: relative;
}

.writeFormGroup {
  margin-left: 150px;
  display: flex;
  align-items: center;
}

.writeIcon {
  width: 25px;
  height: 25px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 50%;
  color: rgb(129, 125, 125);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: -30px;
}

.writeInput {
  margin-left: 0;
  font-size: 30px;
  border-bottom: thick double #505A38;
  margin-bottom: 10px;
  padding: 20px;
  width: 70vw;

  background: #c4cab8ad;
  height: 70px;
  font-size: 16px;
  color: #fff;
  box-sizing: border-box;
  min-height: 50px;
}

.writeInput::placeholder {
  color: rgb(189, 185, 185);
  font-weight: 400;
}

.writeInput:focus {
  outline-style: none;
}

.writeText {
  width: 70vw;
  height: 100vh;
  font-family: inherit;
  font-size: 20px;

  background: #c4cab8ad;
  height: 430px;
  font-size: 16px;
  color: #fff;
  box-sizing: border-box;
  min-height: 150px;
}

.writeSubmit {
  position: absolute;
  bottom: 20px;
  right: 50px;
  color: white;
  background-color: #9CA756;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
}

.writeSubmit:hover {
  background-color: #747F47;
}