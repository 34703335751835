
html{
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  /* background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url('assets/images/backgroundwood.jpg'); */
  background-color: #212121;
  background-size: cover;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); */
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* * {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
} */

::-webkit-scrollbar {
  width: 1px;
  /* background-color: #aaa;            */
}


::-webkit-scrollbar-thumb {
  /* background: #505A38; */
  width: 1px;
  
}

