.login {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: url("https://images.pexels.com/photos/768473/pexels-photo-768473.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500");
    // background-size: contain;
  }
  
  .loginTitle {
    font-size: 50px;
    color: #CADF9E;
  }
  
  .loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .loginForm > label {
    margin: 10px 0;
  }
  
  .loginInput {
    padding: 10px;
    background-color: white;
    border: none;
    border-radius: 10px;
  }
  
  .loginInput:focus {
    outline: none;
  }
  
  .loginButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: #ccdf9e71;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
    text-align: center;
  }
  
  .loginRegisterButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #ccdf9e71;
    cursor: pointer;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 10px;
  }

  .loginRegisterButton:hover,
  .loginButton:hover {
    background-color:  #747f47a9;
  }
 
  .loginButton:disabled{
    cursor: not-allowed;
    background-color: #ccdf9eb0;
  }

  .loginRegisterButton {
    a {
      text-decoration: none;
      color: white;
    }
  }