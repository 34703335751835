.page {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
}

.top-tags {
    bottom: auto;
    top: 35px;
    // padding-top: 10px;
}

.tags {
    color: #CADF9E;
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
    margin-left: 20px;
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 560px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.about-page,
.contact-page,
.projects-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;
        max-height: 90%;
    }

    h1 {
        color: #CADF9E;
        font-size: 40px;
        margin-top: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
        position: relative;
        margin-bottom: 40px;
        left: 10px;

        &::before {
            content: '<h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #CADF9E;
            font-size: 18px;
            position: absolute;
            margin-top: -10px;
            left: -10px;
            opacity: 0.6;
            line-height: 18px;
        }

        &::after {
            content: '</h1>';
            font-family: 'La Belle Aurore', cursive;
            color: #CADF9E;
            font-size: 18px;
            line-height: 18px;
            position: absolute;

            left: -30px;
            bottom: -20px;
            // margin-top: 40px;
            margin-left: 20px;
            // animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

    }

    p {
        font-size: 13px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
        max-width: fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
            animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
            animation-delay: 1.2s;
        }

        &:nth-of-type(3) {
            animation-delay: 1.3s;
        }

        &:nth-of-type(4) {
            animation-delay: 1.4s;
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

@media screen and (max-width: 1100px) { 


    .tags {
        color: #CADF9E;
        opacity: 0.2;
        position: absolute;
        bottom: 0;
        left: 20px;
        font-size: 18px;
        font-family: 'La Belle Aurore';
        margin-left: 20px;
        // margin-top: -50px;
    }

    .top-tags {
        bottom: auto;
        top: 10px;
    }

    .btm-tags {
        bottom: 70px;
    }
}