.post {
    flex: 3;
    max-width: 385px;
    min-width: 200px;

    min-height: 285px;
    margin: 0px 25px 40px 25px;
    display: flex;
    flex-direction: column;
    border: #E9F6D0 1pt solid;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);

}

.postImg {
    max-width: 385px;
    height: 280px;
    object-fit: cover;
    border-radius: 20px;
}

.postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postCat {
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 11px;
    color: #be9656;
    line-height: 19px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle {
    font-family: "Josefin Sans", Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 900;
    margin-top: 15px;
    cursor: pointer;
}

.postDate {
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 13px;
    font-weight: 400;
    color: #b9b9b9;
    margin-top: 15px;
}

.postDesc {
    font-family: "Varela Round", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #b9b9b9;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 10px;
    overflow-y: auto;
}



.post .link:link,
.post .link:visited {
    text-decoration: none;
    color: #CADF9E;
    font-family: 'Montserrat', sans-serif;
}