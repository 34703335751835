.register {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.5)
    //   ),
    //   url("https://images.pexels.com/photos/317355/pexels-photo-317355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    // background-size: cover;

    position: absolute;
    width: 95%;
    margin-left: 100px;
  }
  
  .registerTitle {
    font-size: 50px;
    color: #CADF9E;
  }
  
  .registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .registerForm > label {
    margin: 10px 0;
  }
  
  .registerInput {
    padding: 10px;
    background-color: white;
    border: none;
    border-radius: 10px;
  }
  
  .registerInput:focus {
    outline: none;
  }
  
  .registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: #ccdf9e71;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
    text-align: center;
  }
  
  .registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #ccdf9e71;
    cursor: pointer;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 10px;
  }

  .registerLoginButton:hover,
  .registerButton:hover {
    background-color:  #747f47a9;
  }
 
  .registerLoginButton {
    a {
      text-decoration: none;
      color: white;
    }
  }