.navBar {
    background: #181818;
    width: 100px;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .top {
        flex: 2;
        width: 100%;
        max-height: 80px;
        background-color: none;
        margin-top: 50px;
        // position: absolute;
        background: transparent;
        // top: 0;
        display: trans;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        letter-spacing: 2px;

        color: white;

        .toplink {
            display: block;

        }

        .topImg {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
        }


        .topSearchIcon {
            font-size: 18px;
            color: white;
            cursor: pointer;
        }


    }

    nav:has(a.bl.active) {
        a.bl {
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 1000;
            letter-spacing: 0.5px;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 1;
            transition: all 0.3s ease-out;
        }
    }

    nav {
        display: flex;
        flex-direction: column;
        flex: 9;
        text-align: center;
        justify-content: center;
        // position: relative;
        // height: 210px;
        // top: 35%;
        margin-top: -100px;
        width: 100%;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #CADF9E;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-family: 'Montserrat', sans-serif;
                font-weight: 1000;
                font-size: 11px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }

            // &:last-child {
            //     &::after {
            //         content: 'BLOG';
            //     }
            // }
        }



        a.about-link {
            &::after {
                content: 'ABOUT';
            }
        }

        a.contact-link {
            &::after {
                content: 'CONTACT';
            }
        }

        a.blog-link {
            &::after {
                content: 'BLOG';
            }
        }

        a.projects-link {
            &::after {
                content: 'PROJECTS';
            }
        }

        a.posts-link {
            &::after {
                content: 'POSTS';
            }
        }

        a.active {
            svg {
                color: #CADF9E;
            }

            color: #CADF9E;
        }

        a.blog-login-link,
        a.blog-register-link,
        a.blog-settings-link,
        a.blog-single-link,
        a.blog-write-link,
        a.blog-logout-link {
            display: none;
        }

        a.blog-link.active~a.blog-login-link,
        a.blog-link.active~a.blog-register-link,
        a.blog-link.active~a.blog-settings-link,
        a.blog-link.active~a.blog-single-link,
        a.blog-link.active~a.blog-write-link,
        a.blog-link.active~a.blog-logout-link {
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 1000;
            letter-spacing: 0.5px;
            display: block;
            width: 100%;
            text-align: center;
            opacity: 1;
            transition: all 0.3s ease-out;
        }





    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg {
                    color: #CADF9E;
                }
            }

            &:hover {
                .anchor-icon {
                    color: #CADF9E;
                }
            }
        }
    }
}

.hamburger-icon,
.close-icon {
    display: none;
}

@media screen and (max-width: 1100px) {
    .navBar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1pt solid #ccdf9e9e;
        

        ul,
        nav {
            display: none;
        }

        nav {
            width: 100%;
            top: 0;
            left: 0;
            background: #181818;
            height: 100%;
            margin: 0;
            z-index: 2;
            position: fixed;
            


            &.mobile-show {
                display: block;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10PX;
            }

            a svg {
                opacity: 1 !important;
            }
        }

        .hamburger-icon,
        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            display: block;
        }
    }
}