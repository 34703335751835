.blog-home {
    position: absolute;
    width: 100%;
    margin-left: 100px;
    // height: 90%;
    // bottom: 200px;
    
}

.home {
    width: 95%;
    display: flex;
}
