.projects-page {
    position: absolute;
    padding-left: 30px;
    padding-right: 50px;
    padding-top: 20px;
    // border: white solid 1pt;

    top: 50px;
    left: 30px;
    margin-top: 5px;
    width: calc(100% - 150px);
    // position: initial;
    min-height: 0px;
    height: 85%;
    overflow: auto;

    h1.page-title {
        margin-left: 8%;
        margin-top: 0px;
    }


    // h1 {
    //     color: #fff;
    //     font-size: 40px;
    //     margin: 0;
    //     font-family: 'Coolvetica';
    //     font-weight: 400;}

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-left: 5%;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 33%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(33% - 10px);

        .project-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.2) 0,
                    rgba(0, 0, 0, 1));
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            font-family: 'Montserrat', sans-serif;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #CADF9E;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #cbdf9e54;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #CADF9E, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.75;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

@media screen and (max-width: 1100px) {
    // .container.projects-page {
    //     width: calc(100% - 80px);
    //     padding: 20px;
    //     box-sizing: border-box;
    //     min-height: 0px;
    //     height: 75%;

    //     h1.page-title {
    //         margin-left: 20px;
    //         margin-top: 20px;
    //     }

    //     .image-box {
    //         height: 200px;
    //         max-width: calc(50% - 10px);
    //     }
    // }
    .projects-page {
        // padding-top: 10px;
        overflow-y: auto;

        // border: white 1pt solid;
        min-height: 0px;
        height: 72%;
        padding: 0 15px 0 10px;
        width: 100%;
        left: 0px;



        h1.page-title {
            margin-left: 0%;
            margin-top: 15px;
        }

        .images-container {
            margin: 0 auto;
            display: block;
            align-items: center;
            gap: 15px;
            width: 80%;
            padding-left: 0;
            padding-bottom: 100px;
            // border: white 1pt solid;
        }

        .image-box {
            position: relative;
            // flex: 12;
            height: 300px;
            overflow: hidden;
            border-radius: 10px;
            width: 100%;
            max-width: 500px;
            min-width: 0;
            flex-wrap: wrap;
            margin-bottom: 18px;
            // border: white solid 1pt;
        }


    }
}